import React from "react";
import LineStepper from "../../Components/LineStepper";

import { education } from "./data";
import Section from "../../Components/Section";
import Button from "../../Components/Button";
import { socialMediaList } from "../../Data";

export default function Journey() {
    return (
        <Section className="bg-container" id="about">
            <h2 className="text-center font-semibold text-2xl md:text-4xl text-title mb-8">About Me</h2>
            <div className="flex flex-col md:flex-row items-center gap-8" data-aos="fade-up">
                <div className="flex flex-col gap-3 text-left w-full">
                    <div>I am a graduate in economics with a strong background in research and analysis. During the COVID-19 pandemic, I realized how fast technology was changing the world, which sparked my curiosity. In late 2020, I decided to switch careers, joined bootcamps, and developed IT skills.</div>
                    <div>By mid-2021, I joined <span className="font-semibold cursor-pointer text-title hover:text-primary" onClick={() => window.open("https://theforest.ai/",)}>Forest AI Pte. Ltd</span> as a software engineer, working on various projects.</div>
                    <div>Currently, I’m dedicating my time to personal growth—learning, networking, and preparing for new opportunities. Curious about what I’m working on?</div>
                    <Button className='bg-primary text-white w-fit m-auto md:m-0'
                        endIcon={<i className='uil uil-angle-double-right text-xl' />}
                        onClick={() => window.open(socialMediaList[1].link)}>Let's Connect</Button>
                </div>
                <LineStepper data={education} />
            </div>
        </Section>
    )
}